// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-reservations-js": () => import("./../../../src/pages/admin-reservations.js" /* webpackChunkName: "component---src-pages-admin-reservations-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cancel-reservation-js": () => import("./../../../src/pages/cancel-reservation.js" /* webpackChunkName: "component---src-pages-cancel-reservation-js" */),
  "component---src-pages-closed-js": () => import("./../../../src/pages/closed.js" /* webpackChunkName: "component---src-pages-closed-js" */),
  "component---src-pages-complaints-and-suggestions-js": () => import("./../../../src/pages/complaints-and-suggestions.js" /* webpackChunkName: "component---src-pages-complaints-and-suggestions-js" */),
  "component---src-pages-discount-claimed-js": () => import("./../../../src/pages/discount-claimed.js" /* webpackChunkName: "component---src-pages-discount-claimed-js" */),
  "component---src-pages-discount-js": () => import("./../../../src/pages/discount.js" /* webpackChunkName: "component---src-pages-discount-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reservation-cancelled-js": () => import("./../../../src/pages/reservation-cancelled.js" /* webpackChunkName: "component---src-pages-reservation-cancelled-js" */),
  "component---src-pages-reservation-success-js": () => import("./../../../src/pages/reservation-success.js" /* webpackChunkName: "component---src-pages-reservation-success-js" */),
  "component---src-pages-reservations-js": () => import("./../../../src/pages/reservations.js" /* webpackChunkName: "component---src-pages-reservations-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

